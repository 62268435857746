import React, { ReactElement, useRef, useState } from 'react';
import { useSelector } from '@hooks/redux';
import Chip from '@components/UI/Chip';
import ChevronLeft from '@images/svg-icons/chevron-left.svg';
import StaggerChildren from './animations/StaggerChildren';
import AnimationOpacity from './animations/AnimationOpacity';
import ChipsListSkeleton from '@components/skeletons/ChipsListSkeleton';
import { handleCourseDetailQueryParam, handleOnEnterKeyPress, isUsingDefaultScrollbar } from '@utility/Api';
import { LangMap, Course } from '@model/CoursesClass';
import { buildClickDataTrackingObject } from '@model/TrackingClass';
import { camelCase } from 'lodash';

type Props = {
  items: Array<any>;
  selectedItem: any;
  setItem: (item: any) => void;
  id: string;
  codeField?: string;
  labelField?: string;
  imageField?: string;
  scrollItem?: boolean;
  centerScroll?: boolean;
  isSelected?: (item: any, selected: any) => boolean;
  chipVariant?: 'transparent';
  center?: boolean;
  animationChip?: any;
  loading?: boolean;
  disabled?: boolean;
  course?: Course;
};

const ChipsList = (props: Props): ReactElement => {
  const [showArrows, setShowArrows] = useState(false);
  const [showArrowLeft, setShowArrowLeft] = useState(false);
  const [showArrowRight, setShowArrowRight] = useState(false);
  const lang: LangMap = useSelector(state => state.utils.lang);
  const [centerSlides, setCenterSlides] = useState(false);
  const scrollRef = useRef(null);

  const defaultIsSelected = (item: any) => {
    if (item && props.selectedItem && props.codeField) {
      return item[props.codeField] === props.selectedItem[props.codeField];
    }

    return false;
  };

  const handleInView = () => {
    if (scrollRef?.current) {
      handleScrollUpdate({ target: scrollRef.current });
    }
  };

  const handleScrollUpdate = event => {
    if (!event || !event.target) {
      return;
    }

    const scrollWidth = event.target.scrollWidth;
    const clientWidth = event.target.clientWidth;
    const scrollLeft = event.target.scrollLeft;

    const showCarousel = scrollWidth - 1> clientWidth;
    setShowArrows(showCarousel);

    if (showCarousel) {
      //not center slides if scrollbar is present
      setCenterSlides(false);

      //if scrollLeft is greater than 0, the div has scrolled
      setShowArrowLeft(scrollLeft > 0);

      //get the percentage of remaining scrolling space
      //if the percentage is 100 --> scrolled on maximum rightside
      const showArrowRightTemp = Math.round((100 * scrollLeft) / (scrollWidth - clientWidth));
      setShowArrowRight(showArrowRightTemp < 90);
    } else {
      setShowArrowLeft(false);
      setShowArrowRight(false);

      if (props.center) {
        //center slides
        setCenterSlides(true);
      }
    }
  };

  const scrollToItem = (item: any, index: number) => {
    // console.log('scroll to item', item, index);
    scrollToElement(
      '#' + props.id + ' #_' + item[props.codeField] + '_' + index,
      'smooth',
      'nearest',
      'center',
      props.centerScroll
    );
  };

  const handleChipClick = (item: any, index: number) => {
    buildClickDataTrackingObject("chips",camelCase(item?.label))
    scrollToElement(
      '#' + props.id + ' #_' + item[props.codeField] + '_' + index,
      'smooth',
      'nearest',
      'center',
      props.centerScroll
    );

    props.setItem(item);
    handleCourseDetailQueryParam(props.course, true);
  };

  const scrollToElement = (
    query: string,
    behavior: ScrollBehavior,
    block: ScrollLogicalPosition,
    inline: ScrollLogicalPosition,
    center?: boolean
  ) => {
    let element = document.querySelector(query) as HTMLElement;
    if (element) {
      if (center) {
        let scrollElement = scrollRef?.current?.scrollerElement as HTMLElement;
        let clientWidth = scrollRef.current.getScrollState()?.clientWidth;

        if (isUsingDefaultScrollbar()) {
          scrollElement = document.querySelector('#' + props.id + ' .chipslist') as HTMLElement;
          clientWidth = scrollElement.clientWidth;
        }

        if (scrollElement) {
          scrollElement.scrollTo({
            left: element?.offsetLeft + (element?.offsetWidth - clientWidth) / 2,
            behavior: 'smooth',
          });
        }
      } else {
        element.scrollIntoView({ behavior: behavior, block: block, inline: inline });
      }
    }
  };

  const goPrevNext = (e, isPrev: boolean) => {
    e.stopPropagation();

    if (scrollRef?.current) {
      let scrollLeft: number = scrollRef.current.scrollLeft;
      const offsetScroll: number = scrollRef.current.clientWidth / 2;
      if (isPrev) {
        scrollLeft -= offsetScroll;
      } else {
        scrollLeft += offsetScroll;
      }
      scrollRef.current.scrollTo({ behavior: 'smooth', left: scrollLeft });
    }
  };

  return (
    <div className="chipslist-wrapper" id={props.id}>
      {!props.loading ? (
        <>
          {showArrows && showArrowLeft && (
            <div className="arrow-background arrow-background-left">
              <button
                className="arrow arrow-left"
                aria-label={lang.PREVIOUS_CHIP_LIST}
                onClick={e => goPrevNext(e, true)}
                onKeyDown={event => {
                  handleOnEnterKeyPress(event, () => goPrevNext(event, true));
                }}
              >
                <ChevronLeft />
              </button>
            </div>
          )}
          <div
            className={'chipslist' + (centerSlides ? ' center' : '')}
            ref={scrollRef}
            onScroll={handleScrollUpdate}
          >
            <StaggerChildren
              staggerChildren={0.1}
              key={props.id}
              className="chipslist-content"
              inViewCallback={handleInView}
            >
              {props.items.map((item, index) => (
                <div
                  id={'_' + item[props.codeField] + '_' + index}
                  key={item[props.codeField] + '_' + index}
                  className="chipslist-chip"
                >
                  <AnimationOpacity {...props.animationChip}>
                    <Chip
                      label={item[props.labelField]}
                      scrollToItem={() => scrollToItem(item, index)}
                      scrollItem={props.scrollItem}
                      toggle={() => handleChipClick(item, index)}
                      checked={
                        props.isSelected
                          ? props.isSelected(item, props.selectedItem)
                          : defaultIsSelected(item)
                      }
                      variant={props.chipVariant}
                      disabled={props.disabled}
                    />
                  </AnimationOpacity>
                </div>
              ))}
            </StaggerChildren>
          </div>
          {showArrows && showArrowRight && (
            <div className="arrow-background arrow-background-right">
              <button
                className="arrow arrow-right"
                aria-label={lang.NEXT_CHIP_LIST}
                onClick={e => goPrevNext(e, false)}
                onKeyDown={event => {
                  handleOnEnterKeyPress(event, () => goPrevNext(event, false));
                }}
              >
                <ChevronLeft />
              </button>
            </div>
          )}
        </>
      ) : (
        <ChipsListSkeleton />
      )}
    </div>
  );
};

ChipsList.defaultProps = {
  codeField: 'code',
  labelField: 'label',
  id: 'chipslist',
  isSelected: null,
  chipVariant: '',
  center: false,
  animationChip: {
    duration: 0.2,
    isStagger: true,
  },
};

export default ChipsList;
