import React, { useEffect, useState } from 'react';
import {
  printActivityType,
  isMultiActivity,
  isVirtualClassroom,
  isActivityBlocked,
  isLivestreamCourse,
  getActivityCtype,
  printHTML,
  isLearningPath,
  isCompletedCourse,
  getCoursesWithinLP,
  isAnEvent,
} from '@utility/Api';
import { Course, CourseActivity, CourseLanguage, CoursesMap, LangMap } from '@model/CoursesClass';
import CardController, { CardSmall } from '@components/CardV3';
import SessionActivities from './SessionActivities';
import CardSmallSkeleton from '@components/skeletons/CardSmallSkeleton';
import CardActivity from '@components/CardActivity';
import useGetDetailsMultiactivity, {
  DetailMultiactivityClass,
} from '@hooks/useGetDetailsMultiactivity';
import { getActivityCompletionPercentage, getActivityDuration } from '@utility/activityUtility';
import { canCourseBeAccessed } from '@utility/ecommerceUtility';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../store/actions';
import { UserProfile } from '@model/User';


type Props = {
  course: Course;
  lang: LangMap;
  isLoadingDetails: boolean;
  subtitle: CourseLanguage;
  openActivity: (activity: CourseActivity, course: Course, subtitle: CourseLanguage) => void;
  coursesMap?: CoursesMap;
  isLoadingCourse?: Boolean;
};

const DetailCourseActivities = (props: Props) => {
  const detailsMultiactivity: DetailMultiactivityClass[] = useGetDetailsMultiactivity(
    props.course,
    props.subtitle?.code
  );
  const [completedCourseNumber, setCompletedCourseNumber] = useState<number>(0);
  const [getSummaryCourse, setGetSummaryCourse] = useState<boolean>(true);
  const [courses, setCourses] = useState<Course[] | null>(null);
  const trainers = props?.course?.liveInfo?.[0]?.trainers;
  const dispatch = useDispatch();
  const coursesMap: CoursesMap = useSelector(state => state.course.coursesMap);
  const userProfile: UserProfile = useSelector(state => state.user.userProfile);
  const isLoadingCourseSummary = useSelector(state => state.course.isLoadingCourseSummary);
  const isLoadingCourse: boolean = useSelector((state) => state.course.isLoadingCourse);

  useEffect(() => {
    if (isLearningPath(props?.course)) {
      const lpCourses = getCoursesWithinLP(props.course, props.coursesMap);
      setCourses(lpCourses);
    }
  }, [props.course, props.coursesMap]);

  useEffect(() => {
    if (courses) {
      let number = 0;
      courses.forEach(course => {
        if (isCompletedCourse(course)) {
          number = number + 1;
        }
        setCompletedCourseNumber(number);
      });

      if((courses.every(course => !isLoadingCourseSummary?.[course.courseId]) && courses.some(course => coursesMap[course.courseId]?.courseSummary === undefined)) && isLoadingCourse[props?.course.courseIdMaster] !== true ){
      dispatch(actions.getCourseSummary(courses, coursesMap))}
    }
  }, [courses, userProfile.preferredLang,coursesMap,isLoadingCourse[props.course.courseIdMaster]]);

  return (
    <>
      {(!!(props.course.courseDetails && props.course.courseDetails.length > 0) ||
        isLivestreamCourse(props.course) ||
        isLearningPath(props.course)) && (
        <section id="course-activities" className="course-activities">
          {isLivestreamCourse(props.course) && trainers.length > 0 && (
            <div className="session-section" key="speakers">
              <SessionActivities course={props.course} isSpeaker />
            </div>
          )}

          {isMultiActivity(props.course) && canCourseBeAccessed(props.course) && (
            <div className="course-activities-multiactivity">
              {!isAnEvent(props.course) && (
                <span
                  className="course-activities-multiactivity__title"
                  dangerouslySetInnerHTML={printHTML(
                    props.lang.RATIO_ACTIVITIES_COMPLETED.replace(
                      '{ratio}',
                      `
                <span class='percentage ${
                  props.course.numActivities?.completedActivities ===
                  props.course.numActivities?.totalActivities
                    ? 'theme-color-text'
                    : ''
                }'>
                  <span class='num-completed ${
                    props.course.numActivities?.completedActivities > 0 && 'theme-color-text'
                  }'>${props.course.numActivities?.completedActivities || 0}</span>/${
                        props.course.numActivities?.totalActivities || 0
                      }
                </span>
                `
                    )
                  )}
                />
              )}
              {detailsMultiactivity?.map(detail => (
                <CardActivity 
                  key={detail.id}
                  {...detail}
                  image={props?.course?.courseOverviewFile}
                  course={props.course}
                  activity={detail}
                  hidePlayButton={canCourseBeAccessed(props.course)}
                  fatherCourseId={props?.course?.courseIdMaster}
                />
              ))}
            </div>
          )}

          {isLearningPath(props.course) && (
            <div className="course-activities-multiactivity">
              {!isAnEvent(props.course) && (
                <span
                  className="course-activities-multiactivity__title"
                  dangerouslySetInnerHTML={printHTML(
                    props.lang.RATIO_ACTIVITIES_COMPLETED.replace(
                      '{ratio}',
                      `
                <span class='percentage ${
                  completedCourseNumber === courses?.length || 0 ? 'theme-color-text' : ''
                }'>
                  <span class='num-completed ${
                    completedCourseNumber > 0 && 'theme-color-text'
                  }'>${completedCourseNumber}</span>/${courses?.length}
                </span>
                `
                    )
                  )}
                />
              )}
              {courses?.map((course, i) => {
                return (
                  <CardController
                    key={i}
                    course={course}
                    fatherCourseId={props?.course?.courseIdMaster}
                    variant="lesson-in-onboarding"
                    delayImageLoading
                    delayGetCourseDetails
                    orderNum={i + 1}
                    learningPath={props.course}
                  />
                );
              })}
            </div>
          )}

          {!isLivestreamCourse(props.course) &&
            !isMultiActivity(props.course) &&
            props.course?.courseDetails
              ?.sort((a, b) => a.sectionPosition - b.sectionPosition)
              .map((section, i) => {
                let sectionTitle = section.sectionName;

                if (section.content && section.content.length > 0) {
                  const firstActivity = section.content[0];

                  if (firstActivity.type !== 'facetoface') {
                    if (section.content.length === 1) {
                      sectionTitle = firstActivity.name;
                    }
                  }
                }

                return (
                  section.sectionName !== 'completed' && (
                    <div className="course-section" key={section.sectionName}>
                      {section.content?.[0]?.type !== 'facetoface' && (
                        <span className="text-14-medium activity-title">{sectionTitle}</span>
                      )}
                      <div className="course-activities">
                        {section.content
                          ?.sort((a, b) => a.activityPosition - b.activityPosition)
                          .map(activity =>
                            activity.type === 'facetoface' ? (
                              <div
                                className="session-section"
                                key={section.sectionName}
                                id="session-section"
                              >
                                <SessionActivities course={props.course} />
                              </div>
                            ) : (
                              activity.type !== 'customcert' &&
                              activity.type !== 'feedback' && (
                                <CardSmall
                                  key={activity.name + activity.type}
                                  className="card-activity"
                                  isActivity={true}
                                  courseFullName={activity.name}
                                  iconName={getActivityCtype(activity)}
                                  ctypeName={printActivityType(activity, props.lang)}
                                  duration={getActivityDuration(activity)}
                                  showBar={getActivityCompletionPercentage(activity) > 0}
                                  valueBar={getActivityCompletionPercentage(activity)}
                                  openCourseModal={() =>
                                    props.openActivity(activity, props.course, props.subtitle)
                                  }
                                  isNotYetAvailable={isActivityBlocked(activity, section)}
                                  tabIndex={0}
                                />
                              )
                            )
                          )}
                      </div>
                    </div>
                  )
                );
              })}
        </section>
      )}
      {props.isLoadingDetails?.[props.course.courseIdMaster] &&
        !props.course.courseDetails &&
        /*isMultiActivity(props.course) || */ isVirtualClassroom(props.course) && (
          <div className="course-section">
            <div className="course-activities">
              {[...Array(4)].map((e, i) => (
                <div key={i} className="card-activity">
                  <CardSmallSkeleton />
                </div>
              ))}
            </div>
          </div>
        )}
    </>
  );
};

export default DetailCourseActivities;
