import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { Props as SelectProps } from '@components/UI/Select';
import { useSelector } from '@hooks/redux';
import useLanguageSelection from '@hooks/useLanguageSelection';
import { buildClickDataTrackingObject } from '@model/TrackingClass';
import camelCase from 'lodash/camelCase';
import lang from '@utility/lang';
import { LangMap } from '@model/CoursesClass';
import { USER_URLS } from '@components/link-utils';

export type Props = Omit<SelectProps, 'isLoading' | 'options' | 'onChange' | 'value'>;

const LanguageSelect = (props: Props) => {
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated);
  const { i18n, t } = useTranslation();
	const lang: LangMap = useSelector(state => state.utils.lang);
  const [languages, selectedLanguage, setLanguage] = useLanguageSelection(); //LOGGED LANGUAGES
  const NotLoggedLanguages = {
    //NOT LOGGED LANGUAGES
    en: { value: 'en', label: 'English' },
    it: { value: 'it', label: 'Italiano' },
    es: { value: 'es', label: 'Español' },
    pt_br: { value: 'pt_br', label: 'Português' },
    fr: { value: 'fr', label: 'Français' },
    //TICKET LEON-6710 REMOVING LANGUAGE FROM PRELOGIN PAGE
    //zh_cn: { value: 'zh_cn', label: '中文（简体)' },
  };

  const changeLanguage = language => {

    const preservedHistoryPath = sessionStorage.getItem('historyPath');

    isAuthenticated ? setLanguage(language) : i18n.changeLanguage(language.value);
    
    if (preservedHistoryPath) {
      sessionStorage.setItem('historyPath', preservedHistoryPath);
    }

    // LEON-6509
	// Set Y in order to prevent scroll up after language change in Safari
    // Function to unlock scroll 
    const unlockScroll = () => {
      document.body.style.overflow = 'unset';
    };

    // Function to disconnect the observer and unlock the scroll
    const disconnectObserver = () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
      unlockScroll();
    };

    let stabilizationTimeout;

    const resizeObserver = new ResizeObserver(() => {
      window.requestAnimationFrame(() => {
        window.scrollTo({ top: document.body.scrollHeight });
      });

      setTimeout(unlockScroll, 100);
      if (stabilizationTimeout) clearTimeout(stabilizationTimeout);
      stabilizationTimeout = setTimeout(() => {
        disconnectObserver();
      }, 2000);
    });

    //Adds listener to DOMContentLoaded
    const onDOMContentLoaded = () => {

      window.requestAnimationFrame(() => {
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

        if (isSafari) {
          //Add a small delay to mitigate recalculations on Safari
          setTimeout(() => {
            window.scrollTo({ top: document.body.scrollHeight });
          }, 100); //Short delay for stability on Safari
        } else {
          //Normal scroll for other browsers
          window.scrollTo({ top: document.body.scrollHeight });
        }
      });

    //Observe the changes in the body
    resizeObserver.observe(document.body);
    };

    if (document.readyState === "loading") {
      //If the DOM is not ready yet, add the event
      document.addEventListener("DOMContentLoaded", onDOMContentLoaded);
    } else {
      //If the DOM is already ready, run it directly
      onDOMContentLoaded();
    }

    //Cleanup function 
    return () => {
      document.removeEventListener("DOMContentLoaded", onDOMContentLoaded);
      disconnectObserver();
    };
  };

  return (
    <Select
      labelFixedTop={true}
      options={isAuthenticated ? languages : Object.values(NotLoggedLanguages)}
      onChange={option =>{ buildClickDataTrackingObject("footer_language",camelCase(option?.label));changeLanguage(option)}}
      value={
        isAuthenticated
          ? selectedLanguage
          : NotLoggedLanguages[i18n.language] || NotLoggedLanguages['en']
      }
      removeLeftPadding
      isSearchable
      aria-label={props.ariaLabel && props.ariaLabel}
      // placeholder={props.ariaLabel && props.ariaLabel}
      fillSpace
      {...props}
    />
  );
};

LanguageSelect.displayName = 'LanguageSelect';

export default LanguageSelect;
